<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSelection @change="onFilterChange" /></keep-alive>
    <basic-table
      hiddenClear
      hiddenPage
      tableHeight="700px"
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onRefresh="onRefresh"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'rate'"
              >{{ (scope.row.rate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'signedRate'"
              >{{ (scope.row.signedRate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'signedCount'"
              >{{ scope.row.signedCount }} 个</span
            >
            <span v-else-if="column.prop === 'count'"
              >{{ scope.row.count }} 个</span
            >
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <div>
          <span style="padding-right: 10px">总数量：{{ dataTotal }} 个</span>
          <span style="padding-right: 10px"
            >总签约数量：{{ signedCountTotal }} 个</span
          >
          <span style="padding-right: 10px"
            >总签约比：{{ (signedCountTotal / dataTotal).toFixed(3) }} %</span
          >
        </div>
      </template>
    </basic-table>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetClientsStatisticsTypes } from "./api";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import FilterSelection from "./components/FilterSelection";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import { ReadLocalColumn } from "@/utils/table";
export default {
  components: {
    BasicTable,
    FilterSelection,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      deleteLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "province",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {},
      dataTotal: 0,
      signedCountTotal: 0,
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "typeName",
            label: "客户类型",
            showColumn: true,
            minWidth: "120px",
            align: "left",
            sortable: true,
          },

          {
            prop: "rate",
            label: "比例",
            showColumn: true,
            minWidth: "120px",
            sortable: true,
          },
          {
            prop: "signedCount",
            label: "签约数量",
            showColumn: true,
            minWidth: "120px",
            sortable: true,
          },
          {
            prop: "signedRate",
            label: "签约比例",
            showColumn: true,
            minWidth: "150px",
            sortable: true,
          },
          {
            prop: "count",
            label: "数量",
            showColumn: true,
            minWidth: "150px",
            sortable: true,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetClientsStatisticsTypes({
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          // this.tableData = res.data;
          this.signedCountTotal = 0;
          res.data.forEach((item) => {
            this.signedCountTotal += item.signedCount;
          });
          if (this.tableFilters.typeId  && this.tableFilters.typeId !== null && this.tableFilters.typeId.length > 0) {
            this.tableData = res.data.filter((item) => {
              if (this.tableFilters.typeId.includes(item.typeId)) {
                return item;
              }
            });
          } else {
            this.tableData = res.data;
          }
          this.total = res.data.length;
          this.dataTotal = res.data.length > 0 ? res.data[0].total : 0;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "typeName";
        this.orderDirection = "descending";
      } else {
        this.orderDirection = order;
        this.orderProp = prop;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>